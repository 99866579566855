import { useCallback, useEffect, useState } from "react";
import axios from "../../../../ApiClient";
import { BearbeitungsStatus } from "../../../../Models/Anforderung";
import AnforderungsResult from "../AnforderungsResult";

const LOGISTIK_ANFORDERUNGEN_URL = "/angelo/logistik/anforderungen";

const useGetAnforderungen = (status?: BearbeitungsStatus[], history?: boolean) => {
    const [refresh, setRefresh] = useState(false);
    const [data, setData] = useState<AnforderungsResult>({
        count: 0,
        page: 0,
        pageSize: 0,
        results: [],
    } as AnforderungsResult);
    const [error, setError] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [filter, setFilter] = useState<AnforderungsQueryFilter>(defaultFilter);
    const [sort, setSort] = useState<SortItem>();

    const getSortQuery = useCallback(() => {
        if (sort !== undefined) {
            if (sort.field && sort.sort && (sort.sort === "asc" || sort.sort === "desc")) {
                return `&sort=${sort.field}&sortOrder=${sort.sort}`;
            }
        }
        return "";
    }, [sort]);

    const getStatusQuery = useCallback(() => {
        if (status) {
            return (
                "&" +
                status
                    .map((elem) => {
                        return `status=${elem}`;
                    })
                    .join("&")
            );
        }
        return "";
    }, [status]);

    const getPaginationQuery = useCallback(() => {
        return `page=${filter.page}&pageSize=${filter.pageSize}`;
    }, [filter]);

    const getHistoryQuery = useCallback(() => {
        if (history) {
            return "&history=true";
        }
        return "";
    }, [history]);

    useEffect(() => {
        setLoaded(false);
        const loadData = async () => {
            try {
                let query = `?${getPaginationQuery()}${getStatusQuery()}${getSortQuery()}${getHistoryQuery()}&search=${filter.search}`;
                const response = await axios.get<AnforderungsResult>(LOGISTIK_ANFORDERUNGEN_URL.concat(query));
                const json = response.data;
                setData(json);
            } catch (error: any) {
                setError(error.message);
            } finally {
                setLoaded(true);
            }
        };
        loadData();
    }, [refresh, filter, sort, getPaginationQuery, getHistoryQuery, getStatusQuery, getSortQuery]);

    const refreshAnforderungen = () => {
        setRefresh((curr) => !curr);
    };

    return { data, error, loaded, setFilter, setSort, refreshAnforderungen };
};

export default useGetAnforderungen;

export type AnforderungsQueryFilter = {
    page: number;
    pageSize: number;
    search: string;
};
export const defaultFilter = { page: 1, pageSize: 20, search: "" } as AnforderungsQueryFilter;

export type SortItem = {
    field: string;
    sort: "asc" | "desc" | null | undefined;
};
