import { Alert, Box } from "@mui/material";
import { BearbeitungsStatus } from "../../../../Models/Anforderung";
import { useEffect, useState } from "react";
import { AnforderungsSelection } from "../inbearbeitung/AnforderungsSelection";
import { DisplayAnforderung } from "../inbearbeitung/DisplayAnforderung";
import useGetAnforderungen from "../inbearbeitung/useGetAnforderungen";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { LOGISTIK_HISTORIE_PATH } from "../../../../layout/Navigation";

const reqStatus: BearbeitungsStatus[] = [BearbeitungsStatus.Abgeschlossen];

function Historie() {
    const { data, error, loaded, setFilter, setSort } = useGetAnforderungen(reqStatus, true);
    const { bestellnummer } = useParams();

    const [selectedAnforderung, setSelectedAnforderung] = useState<string>();

    const navigate = useNavigate();

    // callback when Anforderung is changed - reselection of Anforderung by 'bestellnummer'.
    const selectedAnforderungChanged = (id: string) => {
        let anf = data.results.find((a) => a.id === id);
        if (anf) {
            let path = generatePath(LOGISTIK_HISTORIE_PATH + ":bestellnummer", {
                bestellnummer: anf.bestellnummer,
            });
            navigate(path);
        }
    };

    // Page is loaded, either with mennuItemClick, or with passed 'bestellnummer' in path.
    useEffect(() => {
        if (data.results && data.results.length > 0) {
            // data is loaded
            if (bestellnummer) {
                // bestellnummer from path - setSelectedANforderung accordingly
                let newSelAnforderungid = data.results.find((a) => a.bestellnummer?.toString() === bestellnummer)?.id;
                if (newSelAnforderungid && newSelAnforderungid !== selectedAnforderung) {
                    setSelectedAnforderung(newSelAnforderungid);
                }
            } else {
                // no bestellnummer - select first Anforderung.
                setSelectedAnforderung(data.results[0].id);
            }
        }
    }, [data, bestellnummer]);

    return (
        <div className="contentDiv">
            {error !== "" && (
                <Alert severity="error">
                    <span>{"Ein Fehler ist aufgetreten! " + error}</span>
                </Alert>
            )}
            <Box
                sx={{
                    flexGrow: 1,
                    display: { md: "flex" },
                }}
            >
                <AnforderungsSelection
                    preSelected={selectedAnforderung}
                    anforderungen={data.results}
                    anforderungenInTotal={data.count}
                    selectAnforderungCallBack={selectedAnforderungChanged}
                    loading={!loaded}
                    filterChangeCallback={(filter) => {
                        setFilter(filter);
                    }}
                    sortChangedCallback={(sortitem) => {
                        setSort(sortitem);
                    }}
                />
                {selectedAnforderung && (
                    <div
                        style={{
                            width: "100%",
                            border: "1px solid rgba(224, 224, 224, 1)",
                            borderRadius: "4px",
                            marginLeft: "5px",
                            padding: "0 15px 15px 15px",
                        }}
                    >
                        <DisplayAnforderung id={selectedAnforderung} anforderungChangedCallback={() => {}} />
                    </div>
                )}
            </Box>
        </div>
    );
}

export default Historie;
