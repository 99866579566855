import { Anforderung } from "@/Models/Anforderung";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import usePutById from "../../../hooks/usePutById";
import ErrorDisplay from "../../../components/ErrorDisplay";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import "dayjs/locale/de";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

type SetAnforderungDueDateProps = {
    anforderung: Anforderung;
    onChange: () => void;
};
export default function SetAnforderungDueDate(props: Readonly<SetAnforderungDueDateProps>) {
    const { anforderung, onChange } = props;
    const [showDialog, setShowDialog] = useState(false);

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                    <Button onClick={() => setShowDialog(true)} size="small" variant="outlined" endIcon={<EditIcon fontSize="small" />}>
                        Datum setzen
                    </Button>
                </Box>
            </Box>
            {showDialog && <EditDueDateDialog anforderung={anforderung} show={showDialog} onClose={() => setShowDialog(false)} onChange={() => onChange()} />}
        </>
    );
}

type EditDueDateDialogProps = {
    anforderung: Anforderung;
    show: boolean;
    onChange: () => void;
    onClose: () => void;
};
function EditDueDateDialog(props: Readonly<EditDueDateDialogProps>) {
    const { show, anforderung, onChange, onClose } = props;
    const [open, setOpen] = useState(show);

    const [dueDate, setDueDate] = useState<Date | null>(anforderung.dueDate);
    const [dayJsDueDate, setDayJsDueDate] = useState(dayjs(dueDate + "Z"));

    const { data, error, isLoading, putObject } = usePutById<Anforderung>("angelo/logistik/anforderungen/{id}");

    useEffect(() => {
        setDueDate(dayJsDueDate.toDate());
    }, [dayJsDueDate]);

    useEffect(() => {
        setOpen(show);
    }, [show]);

    const close = () => {
        setOpen(false);
        onClose();
    };

    const save = () => {
        let obj = {
            id: anforderung.id,
            dueDate: dueDate,
        } as Anforderung;
        putObject(obj);
    };

    useEffect(() => {
        if (data) {
            onChange();
            close();
        }
    }, [data]);

    return (
        <Dialog open={open} onClose={() => close()} maxWidth="lg" fullWidth>
            <DialogTitle>Fälligkeitsdatum setzen</DialogTitle>
            <DialogContent>
                {error && <ErrorDisplay title="Fehler beim laden der Kommissionierer" error={error} />}
                {isLoading && <LinearProgress />}
                <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
                    <Box sx={{ flex: 2 }}>zu bearbeiten bis</Box>
                    <Box sx={{ flex: 9 }}>
                        <br />
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                            <DateTimePicker
                                formatDensity="dense"
                                timezone="Europe/Berlin"
                                label="Fälligkeitsdatum"
                                value={dayJsDueDate}
                                onChange={(newValue) => {
                                    setDayJsDueDate(newValue!);
                                }}
                                viewRenderers={{
                                    hours: null,
                                }}
                            />
                        </LocalizationProvider>
                        <br />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => save()} disabled={isLoading} variant="outlined" size="small" startIcon={<SaveIcon fontSize="small" />}>
                    Speichern
                </Button>
                <Button disabled={isLoading} onClick={() => close()} variant="outlined" size="small" startIcon={<CloseIcon fontSize="small" />}>
                    Schließen
                </Button>
            </DialogActions>
        </Dialog>
    );
}
