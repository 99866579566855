import { Alert, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { BESTELLUNG_ANFORDERUNGEN_PATH } from "../../../layout/Navigation";
import { BearbeitungsStatus } from "../../../Models/Anforderung";
import { DisplayAnforderungBesteller } from "./DisplayAnforderungBesteller";
import useGetAnforderungenOfFirma from "./useGetAnforderungenOfFirma";
import useAuthentication from "./../../../authentication/useAuthentication";
import { AnforderungsSelectionBesteller } from "./AnforderungsSelectionBesteller";

const reqStatus: BearbeitungsStatus[] = [BearbeitungsStatus.Offen, BearbeitungsStatus.InKommissionierung, BearbeitungsStatus.Versendet, BearbeitungsStatus.Teillieferung];
export default function OffeneAnforderungen() {
    const { firmaIdAnforderer } = useAuthentication();
    const { data, loaded, error, setFilter, setSort, refreshAnforderungen } = useGetAnforderungenOfFirma(firmaIdAnforderer, reqStatus);
    const { bestellnummer } = useParams();

    const [selectedAnforderung, setSelectedAnforderung] = useState<string>();

    const navigate = useNavigate();

    // callback when Anforderung is changed - reselection of Anforderung by 'bestellnummer'.
    const selectedAnforderungChanged = (id: string) => {
        const anf = data.results.find((a) => a.id === id);
        if (anf) {
            const path = generatePath(BESTELLUNG_ANFORDERUNGEN_PATH + ":bestellnummer", {
                bestellnummer: anf.bestellnummer,
            });
            navigate(path);
        }
    };

    // Page is loaded, either with mennuItemClick, or with passed 'bestellnummer' in path.
    useEffect(() => {
        if (data && data.results.length > 0) {
            // data is loaded
            if (bestellnummer) {
                // bestellnummer from path - setSelectedANforderung accordingly
                let newSelAnforderungid = data.results.find((a) => a.bestellnummer?.toString() === bestellnummer)?.id;
                if (newSelAnforderungid && newSelAnforderungid !== selectedAnforderung) {
                    setSelectedAnforderung(newSelAnforderungid);
                }
            } else {
                // no bestellnummer - select first Anforderung.
                setSelectedAnforderung(data.results[0].id);
            }
        }
    }, [data, bestellnummer, setSelectedAnforderung]);

    return (
        <div className="contentDiv">
            {error !== "" && (
                <Alert severity="error">
                    <span>{"Ein Fehler ist aufgetreten! " + error}</span>
                </Alert>
            )}
            <Box
                sx={{
                    flexGrow: 1,
                    display: { md: "flex" },
                }}
            >
                <AnforderungsSelectionBesteller
                    anforderungen={data.results}
                    anforderungenInTotal={data.count}
                    loading={!loaded}
                    preSelected={selectedAnforderung}
                    selectAnforderungCallBack={(id: string) => {
                        selectedAnforderungChanged(id);
                    }}
                    filterChangeCallback={(filter) => {
                        setFilter(filter);
                    }}
                    sortChangedCallback={(sortItem) => {
                        setSort(sortItem);
                    }}
                />
                {selectedAnforderung && (
                    <div
                        style={{
                            width: "100%",
                            border: "1px solid rgba(224, 224, 224, 1)",
                            borderRadius: "4px",
                            marginLeft: "5px",
                            padding: "0 15px 15px 15px",
                        }}
                    >
                        <DisplayAnforderungBesteller
                            loading={!loaded}
                            id={selectedAnforderung}
                            anforderungChangedCallback={() => {
                                refreshAnforderungen();
                            }}
                            editable={true}
                        />
                    </div>
                )}
            </Box>
        </div>
    );
}
