import User, { UserRoles, UserTypes } from "../../../Models/User";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, Grid, LinearProgress, MenuItem, Select, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import usePutById from "../../hooks/usePutById";
import ErrorDisplay from "../../components/ErrorDisplay";
import InfoDisplay from "../../components/InfoDisplay";
import { Firma } from "@/Models/Firma";
import useGetById from "./../../../applications/hooks/useGetById";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

type UpdateUserProps = {
    user: User;
    firmen: Firma[];
    onChanged: () => void;
    onClosed: () => void;
};
export default function UpdateUser(props: Readonly<UpdateUserProps>) {
    const { user, firmen, onChanged, onClosed } = props;
    const [showForm, setShowForm] = useState(false);
    const [theUser, setTheUser] = useState<User | null>(user);

    const [newRoles, setNewRoles] = useState<string[]>(user.roles);
    const [newFirmenLaKo, setNewFirmenLaKo] = useState<string[]>(user.firmenLako);
    const [newFirmenAnforderer, setNewFirmenAnforderer] = useState<string[]>(user.firmenAnforderer);
    const [newMail, setNewMail] = useState<string>(user.email);

    const { data, error, isLoading, putObject } = usePutById<User>("angelo/admin/users/{id}");

    const [isFetchingMail, setIsFetchingMail] = useState<boolean>(false);

    const isLaKo = newRoles.includes(UserRoles.LAGERKOORDINATOR);
    const isLaKoValid = (isLaKo && newFirmenLaKo.length > 0) || !isLaKo; // isValid if LaKo and at least one Firma or not LaKo
    const isAnforderer = newRoles.includes(UserRoles.ANFORDERER);
    const isAnfordererValid = (isAnforderer && newFirmenAnforderer.length > 0) || !isAnforderer; // isValid if Anforderer and one Firma or not Anforderer

    const close = () => {
        if (data) {
            onChanged();
        }
        onClosed();
    };

    const save = () => {
        let obj = {
            id: theUser?.id,
            email: newMail,
            roles: newRoles,
            firmenLako: newFirmenLaKo,
            firmenAnforderer: newFirmenAnforderer,
        } as User;
        putObject(obj);
    };

    useEffect(() => {
        if (data) {
            close();
        }
    }, [data]);

    useEffect(() => {
        if (user) {
            setTheUser(user);
        }
    }, [user]);

    useEffect(() => {
        if (theUser) {
            setShowForm(true);
        } else {
            setShowForm(false);
        }
    }, [theUser]);

    useEffect(() => {
        if (!isLaKo) {
            setNewFirmenLaKo([]);
        }
    }, [isLaKo]);

    useEffect(() => {
        if (!isAnforderer) {
            setNewFirmenAnforderer([]);
        }
    }, [isAnforderer]);

    return (
        theUser && (
            <Dialog open={showForm} onClose={() => close()} maxWidth="xl" fullWidth={true}>
                <DialogTitle>Benutzer {theUser.displayName}</DialogTitle>
                <DialogContent>
                    {isLoading && <LinearProgress />}
                    {error && <ErrorDisplay title="Fehler beim Speichern des Benutzers" error={error} />}
                    {data && <InfoDisplay title="Benutzer gespeichert" message="Benutzer erfolgreich gespeichert." />}
                    <Grid container sx={{ alignItems: "center" }}>
                        <Grid item xs={3}>
                            Rollen
                        </Grid>
                        <Grid item xs={9}>
                            <Select value={newRoles} multiline={true} size="small" fullWidth multiple onChange={(evt) => setNewRoles(evt.target.value as string[])}>
                                {UserRoles.ALL_ROLES.map((role) => (
                                    <MenuItem key={role} value={role}>
                                        {role}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={3}>
                            Firmen (Lagerkoordinator)
                        </Grid>
                        <Grid item xs={9}>
                            {firmen && (
                                <Select
                                    disabled={!isLaKo}
                                    value={newFirmenLaKo}
                                    multiline={true}
                                    size="small"
                                    fullWidth
                                    multiple
                                    onChange={(evt) => {
                                        setNewFirmenLaKo(evt.target.value as string[]);
                                    }}
                                >
                                    {firmen.map((firma) => (
                                        <MenuItem key={firma.id} value={firma.id.toString()}>
                                            {firma.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                            {!isLaKoValid && <FormLabel error>Ein Lagerkoordinator benötigt mindestens eine Firma!</FormLabel>}
                        </Grid>
                        <Grid item xs={3}>
                            Firmen (Anforderer)
                        </Grid>
                        <Grid item xs={9}>
                            {firmen && (
                                <Select
                                    disabled={!isAnforderer}
                                    value={newFirmenAnforderer}
                                    multiline={false}
                                    size="small"
                                    fullWidth
                                    onChange={(evt) => {
                                        setNewFirmenAnforderer([evt.target.value] as string[]);
                                    }}
                                >
                                    {firmen.map((firma) => (
                                        <MenuItem key={firma.id} value={firma.id.toString()}>
                                            {firma.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                            {!isAnfordererValid && <FormLabel error>Ein Anforderer benötigt eine Firma!</FormLabel>}
                        </Grid>
                        <Grid item xs={2}>
                            Email
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "end", alignContent: "center" }}>
                            {user.type === UserTypes.INTERN && (
                                <FetchEmail
                                    user={user}
                                    inProgress={(inProgress) => {
                                        setIsFetchingMail(inProgress);
                                    }}
                                    onLoaded={(mail) => setNewMail(mail)}
                                />
                            )}
                        </Grid>
                        <Grid item xs={9}>
                            <TextField disabled={true} size="small" fullWidth name="email" value={newMail} onChange={(evt) => setNewMail(evt.currentTarget.value)} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={isFetchingMail || isLoading || !(isLaKoValid && isAnfordererValid)}
                        onClick={() => save()}
                        variant="outlined"
                        size="small"
                        startIcon={<SaveIcon fontSize="small" />}
                    >
                        Speichern
                    </Button>
                    <Button disabled={isLoading} onClick={() => close()} variant="outlined" size="small" startIcon={<CloseIcon fontSize="small" />}>
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
        )
    );
}

type FetchEmailProps = {
    user: User;
    onLoaded: (email: string) => void;
    inProgress: (inProgress: boolean) => void;
};
function FetchEmail(props: Readonly<FetchEmailProps>) {
    const { user, inProgress, onLoaded } = props;
    const id = user.id;

    const { data, error, isLoading, loadById } = useGetById<User>("angelo/admin/emails");

    useEffect(() => {
        if (user.type === UserTypes.INTERN) {
            loadById(id);
        }
    }, [id]);

    useEffect(() => {
        inProgress(isLoading);
    }, [isLoading]);

    useEffect(() => {
        if (data) {
            onLoaded(data.email);
        }
    }, [data]);

    return (
        <>
            {isLoading && <CircularProgress size={20} />}
            {data && <CheckCircleIcon fontSize="small" color="success" titleAccess="Email wurde geladen" />}
            {error && <ErrorIcon color="error" titleAccess={error} />}
        </>
    );
}
