import { Alert, Button, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { Anforderung } from "../../../Models/Anforderung";
import { BearbeitungsStatusAsText } from "../../logistik/anforderungen/BearbeitungsStatusAsText";
import { Kommissionierung, KommissionierungsStatus } from "../../../Models/Kommissionierung";
import { KommissionierungsStatusAsText } from "../../logistik/anforderungen/KommissionierungsStatusAsText";
import { KommissionierungsPosition } from "../../../Models/KommissionierungsPosition";
import { useUpdateKommissionierungsStatusOfFirma } from "./useUpdateKommissionierungsStatusOfFirma";
import { useEffect, useState } from "react";
import useGetAnforderungenByFirmaById from "./useGetAnforderungenByFirmaById";
import AnforderungsPositionenTabelleBesteller from "./AnforderungsPositionenTabelleBesteller";
import StornosView from "../../components/StornosView";
import useAuthentication from "./../../../authentication/useAuthentication";
import DateDisplay from "./../../../applications/components/DateDisplay";

export type DisplayAnforderungBestellerProps = {
    id: string;
    anforderungChangedCallback: Function;
    loading: boolean;
    editable?: boolean;
};
export const DisplayAnforderungBesteller = (props: DisplayAnforderungBestellerProps) => {
    const { loading, id, anforderungChangedCallback, editable } = props;
    const { data, error, loaded, loadAnforderungById } = useGetAnforderungenByFirmaById();
    const { firmaIdAnforderer } = useAuthentication();

    const [anforderung, setAnforderung] = useState<Anforderung>();

    const { updateKommissionierungsStatus, updateError, updateLoaded } = useUpdateKommissionierungsStatusOfFirma();

    const saveAnforderungsStatus = async (kommId: string, newStatus: KommissionierungsStatus) => {
        await updateKommissionierungsStatus(firmaIdAnforderer, kommId, newStatus);
        anforderungChangedCallback();
        await loadAnforderungById(firmaIdAnforderer, id);
    };

    const onStorno = async (posId: string) => {
        await loadAnforderungById(firmaIdAnforderer, id);
        anforderungChangedCallback();
    };

    useEffect(() => {
        loadAnforderungById(firmaIdAnforderer, id);
    }, [id]);

    useEffect(() => {
        setAnforderung(data);
    }, [data]);

    if (!loaded) {
        return <LinearProgress />;
    }

    return (
        <>
            {loading && <LinearProgress />}
            {!updateLoaded && <LinearProgress />}
            {(error !== "" || updateError !== "") && (
                <Alert severity="error">
                    <span>{"Ein Fehler ist aufgetreten! " + updateError}</span>
                </Alert>
            )}
            {anforderung && (
                <>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell width={"18%"} variant="head">
                                    Anforderer
                                </TableCell>
                                <TableCell width={"32%"}>{anforderung.anforderer.name}</TableCell>
                                <TableCell width={"15%"} variant="head">
                                    Besteller
                                </TableCell>
                                <TableCell width={"35%"}>{anforderung.bestellerEmail}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Abrufnummer</TableCell>
                                <TableCell>{anforderung.bestellnummer}</TableCell>
                                <TableCell width={"18%"} variant="head">
                                    Lager
                                </TableCell>
                                <TableCell width={"32%"}>{anforderung.lager.name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Bemerkung Anforderer</TableCell>
                                <TableCell sx={{ whiteSpace: "pre-wrap" }}>{anforderung.anfordererBemerkung}</TableCell>
                                <TableCell>Bemerkung Logistik</TableCell>
                                <TableCell>{anforderung.logistikBemerkung}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Status Abruf</TableCell>
                                <TableCell>
                                    <BearbeitungsStatusAsText status={anforderung.bearbeitungsStatus} />
                                </TableCell>
                                <TableCell>Bestelleingang</TableCell>
                                <TableCell>
                                    <DateDisplay date={anforderung.createdAt} showTime={true} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <br />
                    <AnforderungsPositionenTabelleBesteller
                        positionen={anforderung.positionen}
                        kommissionierungen={anforderung.kommissionierungen}
                        stornierungen={anforderung.stornierungen}
                        stornoCallback={(posId: string) => {
                            onStorno(posId);
                        }}
                    />
                    <Kommissionierungen
                        editable={editable}
                        kommissionierungen={anforderung.kommissionierungen}
                        statusChangedCallback={(kommId: string, status: KommissionierungsStatus) => {
                            saveAnforderungsStatus(kommId, status);
                        }}
                    />
                    <StornosView stornos={anforderung.stornierungen} />
                </>
            )}
        </>
    );
};

type KommissionierungenProps = {
    kommissionierungen: Kommissionierung[];
    statusChangedCallback: (kommId: string, newValue: KommissionierungsStatus) => void;
    editable?: boolean;
};
const Kommissionierungen = (props: KommissionierungenProps) => {
    const { kommissionierungen, statusChangedCallback } = props;
    const warenAngenommen = (kommId: string) => {
        statusChangedCallback(kommId, KommissionierungsStatus.WarenAngenommen);
    };
    const warenIO = (kommId: string) => {
        statusChangedCallback(kommId, KommissionierungsStatus.WarenIO);
    };

    return (
        <>
            <hr />
            <h3>Warenlieferungen</h3>
            {kommissionierungen && kommissionierungen.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ISU Lieferschein</TableCell>
                            <TableCell>Datum</TableCell>
                            <TableCell sx={{ width: "510px" }}>Status</TableCell>
                            <TableCell>Kommentar</TableCell>
                            <TableCell>Position: Menge</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {kommissionierungen
                            .sort((k, v) => {
                                return new Date(v.createdAt).getTime() - new Date(k.createdAt).getTime();
                            })
                            .map((val: Kommissionierung, index: number) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{val.isuLieferschein}</TableCell>
                                        <TableCell>
                                            <DateDisplay date={val.createdAt} showTime={true} />
                                        </TableCell>
                                        <TableCell>
                                            <Table
                                                sx={{
                                                    [`& .${tableCellClasses.root}`]: {
                                                        borderBottom: "none",
                                                        //border: "1px solid black ! important",
                                                    },
                                                }}
                                            >
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell sx={{ width: "40%" }}>
                                                            <KommissionierungsStatusAsText status={val.status} withIcon={false} />
                                                        </TableCell>
                                                        <TableCell sx={{ width: "60%", textAlign: "right" }}>
                                                            {val.status === KommissionierungsStatus.Versendet && (
                                                                <Button
                                                                    variant="contained"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        warenAngenommen(val.id);
                                                                    }}
                                                                >
                                                                    Waren angenommen
                                                                </Button>
                                                            )}{" "}
                                                            {(val.status === KommissionierungsStatus.Versendet || val.status === KommissionierungsStatus.WarenAngenommen) && (
                                                                <Button
                                                                    variant="contained"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        warenIO(val.id);
                                                                    }}
                                                                >
                                                                    Waren iO
                                                                </Button>
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableCell>
                                        <TableCell>{val.comment}</TableCell>
                                        <TableCell>
                                            {val.positionen.map((pos: KommissionierungsPosition, index: number) => {
                                                return (
                                                    <div key={index}>
                                                        {pos.anforderungsPosition.artikel.artikelnummer}: {pos.anzahl}
                                                    </div>
                                                );
                                            })}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            ) : (
                <>keine</>
            )}
        </>
    );
};
