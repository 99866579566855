import { Menu, MenuItem, Divider, ListItem, ListItemIcon, ListItemText, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import useAuthentication from "authentication/useAuthentication";
import { UserRoles } from "./../Models/User";
import ErrorDisplay from "applications/components/ErrorDisplay";
import LogoutIcon from "@mui/icons-material/Logout";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Firma } from "Models/Firma";
import { Link } from "react-router-dom";
import "./UserProfile.css";
import useGetById from "../applications/hooks/useGetById";
import UserAvatar from "./UserProfilePhoto";

export default function UserProfile() {
    const { isAuthenticated, username, roles, firmaIdAnforderer, hasRole, logout } = useAuthentication();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    if (isAuthenticated) {
        return (
            <>
                <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" color="inherit" onClick={handleClick}>
                    <UserAvatar />
                </IconButton>
                {username}
                <Menu id="menu-appbar" keepMounted anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <ListItem>Rollen</ListItem>
                    <Divider />
                    {roles.map((role) => {
                        return <ListItem key={role}>{role}</ListItem>;
                    })}
                    <Divider />
                    {hasRole(UserRoles.ANFORDERER) && <AnfordererFuer firmaId={firmaIdAnforderer} />}
                    <NavigateToSection roles={roles} />
                    <MenuItem onClick={() => logout()}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </MenuItem>
                </Menu>
            </>
        );
    } else {
        return <></>;
    }
}

type NavigateToSectionProps = {
    roles: string[];
};
function NavigateToSection(props: Readonly<NavigateToSectionProps>) {
    const { roles } = props;

    const darfLogistik = roles.includes(UserRoles.KOMMISIONIERER) || roles.includes(UserRoles.KOORDINATOR);
    const darfBestellung = roles.includes(UserRoles.ANFORDERER);
    const darfAdmin = roles.includes(UserRoles.ADMINISTRATOR) || roles.includes(UserRoles.KOORDINATOR);

    // filter removes all false values, length gives the count of true values
    const boolSum = [darfLogistik, darfBestellung, darfAdmin].filter(Boolean).length;

    if (boolSum >= 2) {
        return (
            <>
                <ListItem>Bereich</ListItem>
                {darfBestellung && (
                    <MenuItem>
                        <ListItemIcon>
                            <NavigateNextIcon />
                        </ListItemIcon>
                        <ListItemText>
                            <Link to="/bestellung/">Bestellersicht</Link>
                        </ListItemText>
                    </MenuItem>
                )}
                {darfLogistik && (
                    <MenuItem>
                        <ListItemIcon>
                            <NavigateNextIcon />
                        </ListItemIcon>
                        <ListItemText>
                            <Link to="/logistik/">Logistiksicht</Link>
                        </ListItemText>
                    </MenuItem>
                )}
                {darfAdmin && (
                    <MenuItem>
                        <ListItemIcon>
                            <NavigateNextIcon />
                        </ListItemIcon>
                        <ListItemText>
                            <Link to="/administration/">Administration</Link>
                        </ListItemText>
                    </MenuItem>
                )}
                <Divider />
            </>
        );
    }
    return <></>;
}

type AnfordererFuerProps = {
    firmaId: string;
};
function AnfordererFuer(props: Readonly<AnfordererFuerProps>) {
    const { firmaId } = props;
    const { data, error, loadById } = useGetById<Firma>("angelo/firmen");

    useEffect(() => {
        loadById(firmaId);
    }, [firmaId, loadById]);

    return (
        <>
            <ListItem>
                <>Anforderer für: {data?.name}</>
                {error && <ErrorDisplay title="Fehler beim laden der Firma" error={error} />}
            </ListItem>
            <Divider />
        </>
    );
}
