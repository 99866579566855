import { Button, Dialog, DialogContent, DialogTitle, LinearProgress, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import useGetById from "../../../../applications/hooks/useGetById";
import ErrorDisplay from "./../../../components/ErrorDisplay";
import User from "Models/User";

export type BenutzerDialogProps = {
    benutzerMail: string;
};
export default function BenutzerDialog(props: Readonly<BenutzerDialogProps>) {
    const { benutzerMail } = props;
    const [visible, setVisible] = useState(false);

    return (
        <>
            <Button
                color="primary"
                sx={{ marginBottom: "10px" }}
                variant="outlined"
                onClick={(ev) => {
                    setVisible(true);
                }}
            >
                {benutzerMail}
            </Button>
            <DialogOverlay onClose={() => setVisible(false)} visible={visible} benutzerMail={benutzerMail} />
        </>
    );
}
type DialogOverlayProps = {
    benutzerMail: string;
    visible: boolean;
    onClose: () => void;
};
const DialogOverlay = (props: DialogOverlayProps) => {
    const { benutzerMail, visible, onClose } = props;
    const { data, error, isLoading, loadById } = useGetById<User>("angelo/logistik/mitarbeiter");
    const [lastLoaded, setLastLoaded] = useState<string>("");

    useEffect(() => {
        if (visible && benutzerMail !== lastLoaded) {
            loadById(benutzerMail);
            setLastLoaded(benutzerMail);
        }
    }, [benutzerMail, visible, lastLoaded]);

    return (
        <Dialog open={visible} onClose={() => onClose()}>
            <DialogTitle>Benutzer Details</DialogTitle>
            <DialogContent>
                {isLoading && <LinearProgress />}
                {error && <ErrorDisplay title="Benutzer konnte nicht geladen werden" error={error} />}
                <TextField sx={{ marginLeft: "30px", marginRight: "30px" }} margin="normal" size="small" disabled label="Email" defaultValue={benutzerMail} />
                {data && (
                    <>
                        <br></br>
                        <TextField sx={{ marginLeft: "30px", marginRight: "30px" }} margin="normal" size="small" disabled label="Name" defaultValue={data?.displayName} />
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};
