import { LinearProgress } from "@mui/material";
import { DataGridPro, GridColDef, GridSortModel } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import CustomGridToolbarSearchOnServer from "../../../../layout/CustomGridToolbarSearchOnServer";
import { Anforderung } from "@/Models/Anforderung";
import { BearbeitungsStatusAsText } from "../BearbeitungsStatusAsText";
import FlagAnforderungForLogistik from "./FlagAnforderungForLogistik";
import AnforderungsTypIcon from "../../../../layout/AnforderungsTypIcon";
import { AnforderungsQueryFilter, SortItem, defaultFilter } from "./useGetAnforderungen";
import { Firma } from "@/Models/Firma";
import { Lager } from "@/Models/Lager";
import { dateFormat } from "./../../../../applications/components/DateDisplay";
import FaelligkeitDisplay from "./FaelligkeitDisplay";

export type AnforderungsSelectionProps = {
    anforderungen: Anforderung[];
    anforderungenInTotal: number;
    loading: boolean;
    preSelected?: string;
    selectAnforderungCallBack: (id: string) => void;
    filterChangeCallback: (filter: AnforderungsQueryFilter) => void;
    sortChangedCallback: (sortModel: SortItem) => void;
};
export const AnforderungsSelection = (props: Readonly<AnforderungsSelectionProps>) => {
    const [selectedAnforderungId, setSelectedAnforderungId] = useState<string>();

    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: defaultFilter.pageSize });

    const [filter, setFilter] = useState<AnforderungsQueryFilter>(defaultFilter);
    const [sort, setSort] = useState<SortItem>();

    const { anforderungen, anforderungenInTotal, filterChangeCallback, sortChangedCallback } = props;

    // preselected value changes or is set
    useEffect(() => {
        if (props.preSelected && props.preSelected !== selectedAnforderungId) {
            setSelectedAnforderungId(props.preSelected);
        }
    }, [props.preSelected, selectedAnforderungId]);

    useEffect(() => {
        filterChangeCallback(filter);
    }, [filter, filterChangeCallback]);

    useEffect(() => {
        sortChangedCallback(sort as SortItem);
    }, [sort, sortChangedCallback]);

    const selectAnforderung = (id: string) => {
        setSelectedAnforderungId(id);
        props.selectAnforderungCallBack(id);
    };

    const columns: GridColDef<Anforderung>[] = [
        {
            field: "bearbeitungsStatus",
            headerName: "Status",
            width: 60,
            renderCell: (p) => {
                return (
                    <>
                        <BearbeitungsStatusAsText status={p.value} />
                        <FlagAnforderungForLogistik anforderung={p.row} />
                    </>
                );
            },
        },
        {
            field: "anforderungsTyp",
            headerName: "Typ",
            width: 50,
            renderCell: (elem) => <AnforderungsTypIcon type={elem.value} />,
        },
        { field: "bestellnummer", headerName: "Nummer", width: 80 },
        {
            field: "createdAt",
            headerName: "Datum",
            width: 85,
            valueFormatter: (a) => {
                return dateFormat(a);
            },
        },
        {
            field: "dueDate",
            headerName: "Fälligkeit",
            width: 85,
            renderCell: (a) => {
                return <FaelligkeitDisplay date={a.value} status={a.row.bearbeitungsStatus} />;
            },
        },
        {
            field: "anforderer",
            headerName: "Anforderer",
            width: 150,
            valueGetter: (a: Firma) => {
                return a.name;
            },
        },
        {
            field: "lager",
            headerName: "Lager",
            width: 60,
            valueGetter: (l: Lager) => {
                return l?.nummer;
            },
            valueFormatter: (l: Lager) => {
                return l?.nummer;
            },
        },
    ];

    return (
        <DataGridPro
            disableColumnMenu={true}
            disableMultipleColumnsSorting={true}
            paginationMode="server"
            rowCount={anforderungenInTotal}
            pagination
            pageSizeOptions={[20]}
            paginationModel={paginationModel}
            onPaginationModelChange={(pagination) => {
                setPaginationModel(pagination);
                setFilter({
                    page: pagination.page + 1,
                    pageSize: pagination.pageSize,
                    search: filter.search,
                } as AnforderungsQueryFilter);
            }}
            sortingMode="server"
            onSortModelChange={(sortModel: GridSortModel) => {
                if (sortModel[0]) {
                    setSort(sortModel[0]);
                }
            }}
            loading={props.loading}
            getRowId={(r) => r.id}
            onRowClick={(p) => {
                selectAnforderung(p.row.id);
            }}
            rows={anforderungen}
            columns={columns}
            disableRowSelectionOnClick
            sx={{
                minWidth: "585px",
                maxWidth: "585px",

                "& .MuiDataGrid-row:hover": {
                    opacity: 0.8,
                    cursor: "pointer",
                },

                "& .selected": {
                    backgroundColor: "#ddd !important",
                },
            }}
            getRowClassName={(params) => {
                if (params.row.id === selectedAnforderungId) {
                    return "selected";
                }
                return "";
            }}
            getRowHeight={() => "auto"}
            autoHeight
            density="compact"
            slots={{
                toolbar: CustomGridToolbarSearchOnServer as any,
                loadingOverlay: LinearProgress as any,
            }}
            slotProps={{
                toolbar: {
                    onSearch: (searchPhrase: string) =>
                        setFilter({
                            page: 1,
                            pageSize: filter.pageSize,
                            search: searchPhrase,
                        } as AnforderungsQueryFilter),
                },
            }}
        />
    );
};
