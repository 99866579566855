type DateDisplayProps = {
    date: Date;
    showTime?: boolean;
};
export default function DateDisplay(props: Readonly<DateDisplayProps>) {
    const { date, showTime } = props;

    return <>{dateFormat(date, showTime)}</>;
}

export function dateFormat(date: Date, showTime?: boolean): string {
    if (!date) return "-";

    try {
        let dt;
        if (!date.toString().endsWith("Z")) {
            dt = new Date(date + "Z"); // We need to attach UTC timezone manipulator Z!!
        } else {
            dt = new Date(date);
        }

        let result = dt.toLocaleDateString([], { month: "2-digit", day: "2-digit", year: "2-digit" });
        if (showTime) {
            result += " " + dt.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
        }
        return result;
    } catch (error) {
        return "?";
    }
}
