import { AppBar, Box, Button, Toolbar } from "@mui/material";
import useAuthentication from "authentication/useAuthentication";
import { UserRoles } from "Models/User";
import { useMatch } from "react-router-dom";
import UserProfile from "./UserProfile";
import NavigationBestellung from "./NavigationBestellung";
import NavigationLogistik from "./NavigationLogistik";
import NavigationAdmin from "./NavigationAdmin";

export const LOGISTIK_ANFORDERUNGEN_PATH = "/logistik/anforderungsbearbeitung/";
export const LOGISTIK_HISTORIE_PATH = "/logistik/historie/";

export const BESTELLUNG_HISTORIE_PATH = "/bestellung/anforderungshistorie/";
export const BESTELLUNG_ANFORDERUNGEN_PATH = "/bestellung/offeneAnforderungen/";

function Navigation() {
    const { hasRole } = useAuthentication();

    const isLogistikRoute = useMatch("/logistik/*");
    const isAdminRoute = useMatch("/administration/*");

    const isLieferscheinDrucken = useMatch("/logistik/lieferschein/*");

    if (isLieferscheinDrucken) {
        // Do not show a navigation bar, when Lieferschein drucken!
        return (
            <AppBar position="static">
                <Toolbar>{hasRole(UserRoles.KOMMISIONIERER, UserRoles.KOORDINATOR) && <Button sx={{ my: 2, color: "white", display: "block" }}>Lieferschein</Button>}</Toolbar>
            </AppBar>
        );
    }

    let appBarColor: "primary" | "secondary" | "error" = "primary";
    if (isLogistikRoute) {
        appBarColor = "secondary";
    } else if (isAdminRoute) {
        appBarColor = "error";
    }

    return (
        <AppBar color={appBarColor} position="static">
            <Toolbar>
                <Box sx={{ flexGrow: 1, display: { md: "flex" } }}>
                    {hasRole(UserRoles.ANFORDERER) && <NavigationBestellung />}
                    {hasRole(UserRoles.KOMMISIONIERER, UserRoles.KOORDINATOR) && <NavigationLogistik />}
                    {hasRole(UserRoles.ADMINISTRATOR, UserRoles.KOORDINATOR) && <NavigationAdmin />}
                </Box>
                <UserProfile />
            </Toolbar>
        </AppBar>
    );
}

export default Navigation;
