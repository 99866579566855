import "./DisplayAnforderung.css";
import { Box, Chip, IconButton, LinearProgress, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Anforderung } from "../../../../Models/Anforderung";
import PrintIcon from "@mui/icons-material/Print";
import BenutzerDialog from "./BenutzerDialog";
import { Kommissionierung, KommissionierungsStatus } from "../../../../Models/Kommissionierung";
import { KommissionierungsPosition } from "../../../../Models/KommissionierungsPosition";
import { useUpdateKommissionierungsStatus } from "./useUpdateKommissionierungsStatus";
import { KommissionierungsStatusAsText } from "../KommissionierungsStatusAsText";
import { AnforderungsPositionenTabelle } from "./AnforderungsPositionenTabelle";
import StornosView from "./../../../components/StornosView";
import useGetById from "./../../../../applications/hooks/useGetById";
import ErrorDisplay from "./../../../../applications/components/ErrorDisplay";
import EditKommissioniererForAnforderung from "./EditKommissioniererForAnforderung";
import useAuthentication from "./../../../../authentication/useAuthentication";
import { UserRoles } from "./../../../../Models/User";
import usePutById from "./../../../../applications/hooks/usePutById";
import DateDisplay from "./../../../..//applications/components/DateDisplay";
import SetAnforderungDueDate from "./SetAnforderungDueDate";

export type DisplayAnforderungProps = {
    id: string;
    anforderungChangedCallback: Function;
    editable?: boolean;
};
export const DisplayAnforderung = (props: DisplayAnforderungProps) => {
    const { id, anforderungChangedCallback, editable } = props;

    const [anforderung, setAnforderung] = useState<Anforderung>();

    const { data, error: anfError, isLoading, loadById } = useGetById<Anforderung>("angelo/logistik/anforderungen");

    const { updateKommissionierungsStatus, updateError: updateErrorKomStatus } = useUpdateKommissionierungsStatus();

    const anforderungChanged = async () => {
        await loadById(id);
        anforderungChangedCallback();
    };

    const saveKommissionierungsStatus = async (kommId: string, newStatus: KommissionierungsStatus) => {
        await updateKommissionierungsStatus(kommId, newStatus);
        await anforderungChanged();
    };

    useEffect(() => {
        loadById(id); // reload Anforderung when id changes
    }, [id]);

    useEffect(() => {
        setAnforderung(data); // set Anforderung (state) when loadAnforderungById comes back.
    }, [data]);

    if (!id) {
        return <></>;
    }

    return (
        <>
            {isLoading && <LinearProgress />}
            {anfError && <ErrorDisplay error={anfError} title="Fehler beim Laden der Anforderung" />}
            {updateErrorKomStatus !== "" && <ErrorDisplay error={updateErrorKomStatus} title="Fehler beim Status setzen" />}
            {anforderung && (
                <>
                    <AnforderungHeader anforderung={anforderung} editable={editable} onChange={anforderungChanged} />
                    <hr />
                    <AnforderungsPositionenTabelle
                        anforderung={anforderung}
                        positionen={anforderung.positionen}
                        kommissionierungen={anforderung.kommissionierungen}
                        stornierungen={anforderung.stornierungen}
                        editable={editable}
                        stornoCallback={async (posId: string) => {
                            await anforderungChanged();
                        }}
                        onKommissioniert={async () => {
                            await anforderungChanged();
                        }}
                    />
                    <br />
                    <Kommissionierungen editable={editable} kommissionierungen={anforderung.kommissionierungen} statusChangedCallback={saveKommissionierungsStatus} />
                    <StornosView stornos={anforderung.stornierungen} />
                </>
            )}
        </>
    );
};

type AnforderungHeaderProps = {
    anforderung: Anforderung;
    editable?: boolean;
    onChange: Function;
};
function AnforderungHeader(props: Readonly<AnforderungHeaderProps>) {
    const { anforderung, editable, onChange } = props;

    const { hasRole } = useAuthentication();

    return (
        <Table size="small">
            <TableBody>
                <TableRow>
                    <TableCell width={"15%"} variant="head">
                        Anforderer
                    </TableCell>
                    <TableCell width={"35%"}>{anforderung.anforderer.name}</TableCell>
                    <TableCell width={"15%"} variant="head">
                        Besteller
                    </TableCell>
                    <TableCell width={"35%"}>
                        <BenutzerDialog benutzerMail={anforderung.bestellerEmail} />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell width={"15%"} variant="head">
                        Abrufnummer
                    </TableCell>
                    <TableCell width={"35%"}>{anforderung.bestellnummer}</TableCell>
                    <TableCell width={"15%"} variant="head">
                        Lager
                    </TableCell>
                    <TableCell width={"35%"}>
                        {anforderung.lager.name} ({anforderung.lager.nummer})
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell variant="head">Bearbeiter</TableCell>
                    <TableCell>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Box>
                                {anforderung.kommissionierer.map((k) => {
                                    return <Chip key={k.userId} label={k.name} />;
                                })}
                            </Box>
                            <Box>
                                {hasRole(UserRoles.KOORDINATOR) && editable && (
                                    <EditKommissioniererForAnforderung
                                        anforderung={anforderung}
                                        onChange={() => {
                                            onChange();
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                    </TableCell>
                    <TableCell variant="head">zu bearbeiten bis</TableCell>
                    <TableCell>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Box>
                                <DateDisplay date={anforderung.dueDate} showTime={true} />
                            </Box>
                            <Box>
                                {hasRole(UserRoles.KOORDINATOR) && editable && (
                                    <SetAnforderungDueDate
                                        anforderung={anforderung}
                                        onChange={() => {
                                            onChange();
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Bemerkung Anforderer</TableCell>
                    <TableCell sx={{ whiteSpace: "pre-wrap" }}>{anforderung.anfordererBemerkung}</TableCell>
                    <TableCell>Bemerkung Logistik</TableCell>
                    <TableCell>
                        {editable && <UpdateLogistikBemerkung anforderung={anforderung} value={anforderung.logistikBemerkung} onChange={() => {}} />}
                        {!editable && <>{anforderung.logistikBemerkung}</>}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Status Abruf</TableCell>
                    <TableCell>
                        <>{anforderung.bearbeitungsStatus}</>
                    </TableCell>
                    <TableCell>Bestelleingang</TableCell>
                    <TableCell>
                        <DateDisplay date={anforderung.createdAt} showTime={true} />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}

type UpdateLogistikBemerkungProps = {
    value: string;
    anforderung: Anforderung;
    onChange: () => void;
};
function UpdateLogistikBemerkung(props: Readonly<UpdateLogistikBemerkungProps>) {
    const { value, anforderung, onChange } = props;

    const [bemerkung, setBemerkung] = useState<string>(value ?? "");

    const { error, isLoading, putObject } = usePutById<Anforderung>("angelo/logistik/anforderungen/{id}");

    const save = async () => {
        let obj = {
            id: anforderung.id,
            logistikBemerkung: bemerkung,
        } as unknown as Anforderung;

        await putObject(obj);
        onChange();
    };

    return (
        <>
            {isLoading && <LinearProgress />}
            {error && <ErrorDisplay error={error} title="Fehler beim Speichern" />}
            <TextField
                fullWidth
                variant="outlined"
                value={bemerkung}
                size="small"
                onChange={(el) => setBemerkung(el.currentTarget.value)}
                onBlur={(ev) => {
                    if (anforderung !== undefined && ev.currentTarget.value !== anforderung.logistikBemerkung) {
                        save();
                    }
                }}
            />
        </>
    );
}

type KommissionierungenProps = {
    kommissionierungen: Kommissionierung[];
    statusChangedCallback: (id: string, newValue: KommissionierungsStatus) => void;
    editable?: boolean;
};
const Kommissionierungen = (props: KommissionierungenProps) => {
    const { kommissionierungen, statusChangedCallback, editable } = props;
    const openPrint = (id: string) => {
        window.open(`/logistik/lieferschein/${id}`, "_blank", "noopener,noreferrer");
    };

    return (
        <>
            <hr />
            <h3>Warenlieferungen</h3>
            {kommissionierungen && kommissionierungen.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ISU Lieferschein</TableCell>
                            <TableCell>Datum</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Kommentar</TableCell>
                            <TableCell>Position: Menge</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {kommissionierungen
                            .toSorted((k, v) => {
                                return new Date(v.createdAt).getTime() - new Date(k.createdAt).getTime();
                            })
                            .map((val: Kommissionierung, index: number) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{val.isuLieferschein}</TableCell>
                                        <TableCell>
                                            <DateDisplay date={val.createdAt} showTime={true} />
                                        </TableCell>
                                        <TableCell>
                                            {editable ? (
                                                <KommissionierungsStatusPicker
                                                    initialValue={val.status}
                                                    values={Object.values(KommissionierungsStatus).filter(
                                                        (v) => ![KommissionierungsStatus.WarenAngenommen, KommissionierungsStatus.WarenIO].includes(v)
                                                    )}
                                                    onChange={(newVal: KommissionierungsStatus) => {
                                                        statusChangedCallback(val.id, newVal);
                                                    }}
                                                />
                                            ) : (
                                                <KommissionierungsStatusAsText status={val.status} withIcon={true} />
                                            )}
                                        </TableCell>
                                        <TableCell>{val.comment}</TableCell>
                                        <TableCell>
                                            {val.positionen.map((pos: KommissionierungsPosition, index: number) => {
                                                return (
                                                    <div key={index}>
                                                        {pos.anforderungsPosition.artikel.artikelnummer}: {pos.anzahl}
                                                    </div>
                                                );
                                            })}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => openPrint(val.id)}>
                                                <PrintIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            ) : (
                <>keine</>
            )}
        </>
    );
};

type KommissionierungsStatusPickerProps = {
    initialValue: KommissionierungsStatus;
    values: KommissionierungsStatus[];
    onChange: (newValue: KommissionierungsStatus) => void;
};
const KommissionierungsStatusPicker = (props: KommissionierungsStatusPickerProps) => {
    const { initialValue, values, onChange } = props;
    const [updatedStatus, setUpdatedStatus] = useState<KommissionierungsStatus>(initialValue);
    const user = useAuthentication();

    const allowedValues = () => {
        if (user.hasRole(UserRoles.KOORDINATOR)) {
            return values;
        }
        const kommissioniererStatus = [KommissionierungsStatus.InKommissionierung, KommissionierungsStatus.Versandbereit, KommissionierungsStatus.Versendet];
        return values.filter((v) => kommissioniererStatus.includes(v));
    };

    useEffect(() => {
        setUpdatedStatus(initialValue);
    }, [initialValue]);

    return (
        <Select
            size="small"
            sx={{ width: "100%" }}
            value={updatedStatus}
            renderValue={(v: KommissionierungsStatus) => {
                return <KommissionierungsStatusAsText status={v} withIcon={false} />;
            }}
            onChange={(e) => {
                let newStatus = e.target.value as unknown as KommissionierungsStatus;
                setUpdatedStatus(newStatus);
                onChange(newStatus);
            }}
        >
            {values.map((v, i) => {
                return (
                    <MenuItem value={v} disabled={!allowedValues().includes(v)} key={i}>
                        <KommissionierungsStatusAsText status={v} withIcon={true} />
                    </MenuItem>
                );
            })}
        </Select>
    );
};
